import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/usr/src/app/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "payroll-integrations"
    }}>{`Payroll integrations`}</h1>
    <p>{`Build a top of the shelf payroll integration to Planday that supports employee sync and importing payroll and absence data from Planday. `}</p>
    <p>{`This guide outlines our recommended approach to integrating Planday and a payroll system. A Planday payroll integration may include synchronisation of user data, export of a period’s payroll data as well as absence data from Planday to a payroll system. `}</p>
    <p>{`At Planday we and our partners, have already created some successful integrations to payroll systems, and based on that we’ll share some suggestions to the different features you may want to include in your integration between Planday and your payroll system. `}</p>
    <h3 {...{
      "id": "employee-synchronisation"
    }}>{`Employee synchronisation`}</h3>
    <p>{`As part of a payroll integration you may want to help streamline the user creation flow, by creating new users in Planday when added to the payroll system, and vice versa, or automatically update the values of employees’ fields, like bank account information or contact information, to ensure accuracy and alignment between the two systems. To read more about how to manage employee across Planday and other systems, please check out our guide to `}<a parentName="p" {...{
        "href": "./hr-guide"
      }}>{`employee management integrations`}</a>{`). We suggest you get aquainted with the HR API for regular data fields as well as the Pay API for managing employees’ pay rates and monthly salaries. `}</p>
    <h3 {...{
      "id": "use-salary-codes-to-identify-payroll-items"
    }}>{`Use salary codes to identify payroll items`}</h3>
    <p>{`In Planday Admins can set salary codes for each type of payroll item. This normally corresponds to the salary codes in the payroll system. If correctly set up in the Planday portal, every payroll item in the response object in the Payroll API will have a Salary Code, which you will find in the property `}<inlineCode parentName="p">{`salaryCode`}</inlineCode>{`.`}</p>
    <p>{`As a basic example, worked hours may have a separate Salary Code, and overtime hours may have another one.
In a more advanced case, a business may have a Salary Code per Employee Group which correspond to different pay rate in the payroll system, one Salary Code per shift type which corresponds to absence codes as well as a Salary Code on payroll supplements triggered by shifts that take place during night hours.`}</p>
    <h3 {...{
      "id": "mapping-of-employees"
    }}>{`Mapping of employees`}</h3>
    <p>{`You may want to allow the Admin user to do an employee mapping to make sure that employees are matched to the correct employees across the systems, and this could be achieved by matching for example e-mail addresses and first and last name of the employees.`}</p>
    <p>{`In quite a few cases employees may have a unique number in the payroll system, which is visible to the Admin who does the payroll and is usually aligned with the standard text field on the Employee Edit Form in Planday, called Salary Identifier. If you or your customer already uses Salary Identifiers across Planday which match those in the payroll system and they are familiar with this as a way to identify their payees, it may be a good idea to display this number during employee mapping to aid the person doing the mapping. But bear in mind that the `}<inlineCode parentName="p">{`salaryIdentifier`}</inlineCode>{` property should not be mixed up with `}<inlineCode parentName="p">{`employeeId`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "get-payroll-data"
    }}>{`Get payroll data`}</h3>
    <p>{`To build an integration that transfers payroll data related to a payroll period from Planday to a payroll system, we recommend you to use our `}<inlineCode parentName="p">{`GET /payroll/v{version}/payroll`}</inlineCode>{` endpoint in the Planday Payroll API. This single endpoint includes all payroll data in the portal for one or multiple Departments for a specified date range. There are a few different approaches you may wish to take. You may, for instance, wish to give the user of your integration a UI to manually execute the data synchronisation, or to let them configure a fixed schedule for payruns by allowing them to set up monthly or weekly intervals for when your integration calls the Planday API to get payroll data. We’ve tried to summarise and explain the various objects you’ll find in the response of the `}<inlineCode parentName="p">{`GET /payroll/v{version}/payroll`}</inlineCode>{` endpoint in the Planday Payroll API further down.`}</p>
    <h3 {...{
      "id": "preview-of-payroll-before-sync"
    }}>{`Preview of payroll before sync`}</h3>
    <p>{`In Planday, payroll data can be viewed in the Payroll reports, either in our standard PDF, CSV or Excel formats. In addition to fetching the payroll data, and if your integration has a UI, you may want to let the user preview the payroll data in some format before the user triggers the execution of the data sync to your system. `}</p>
    <h3 {...{
      "id": "exporting-absence"
    }}>{`Exporting absence`}</h3>
    <p>{`In terms of absence there are two ways to handle absence in Planday, either by using the Vacation and Overtime module or by registering absence as shifts in the schedule using special shift types. If you or your customers need the ability to synchronise remaining vacation days or hours on their vacation accounts or lieu overtime balances on their overtime accounts, handle vacation requests and approvals as well as read or post transactions to vacation or overtime accounts, we recommend to explore the Absence API documentation. If you or your customer solely use shifts with specific shift types with a Salary Code per absence type to register absence, you will find everything you need if you carry on reading.`}</p>
    <h2 {...{
      "id": "the-planday-payroll-api-response-explained"
    }}>{`The Planday Payroll API response explained`}</h2>
    <p>{`The Payroll API output reflects the output of the Planday which generates the standard Planday payroll reports. This means that pay data for each employeeId for the specified date range is readily calculated in the same way as it is in the payroll exports.
Payroll objects may have up to one Salary Code (and in some cases up to one Salary Code 2, which is an optional and rarely used feature in Planday), and this final Salary Code determines which type of payroll item the object should be identified as in the payroll system. Also, in most cases Planday Admins will make sure they approve all shifts in the Schedule before they send off the data to payroll, so if your integration will respect this you should use the optional parameter `}<inlineCode parentName="p">{`shiftStaus = Approved`}</inlineCode>{`.`}</p>
    <p>{`There are three categories of payroll items in Planday, payroll items that are related to shifts, payroll items that are related to calendar months and payroll items that are not related to shifts or months. This is reflected by the three objects in the `}<inlineCode parentName="p">{`GET /payroll/v{version}/payroll`}</inlineCode>{` response model:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`shiftsPayroll`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`supplementsPayroll`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`salariedPayroll`}</inlineCode></li>
    </ul>
    <p>{`All data returned includes the units, typically hours, as well as any monetary rates. Some integrations to payroll systems transfer only the units, and consider the payroll system’s records as the “source of truth” when it comes to up-to-date pay rates, whereas others transfer all the monetary values already calculated within Planday. There may be different reasons why some customers and some partners may choose one approach over another.`}</p>
    <h3 {...{
      "id": "shifts-payroll-payroll-items-related-to-shifts"
    }}>{`Shifts Payroll: Payroll items related to shifts`}</h3>
    <p>{`The first object includes all payroll items related to shifts based on the employees’ rates, and it also includes supplements related to the shift. Normally this is the majority of the payroll data for the portal.`}</p>
    <h4 {...{
      "id": "employee-pay-rates"
    }}>{`Employee Pay Rates`}</h4>
    <p>{`Hourly rates and rates per shift are related to an Employee’s relation to an Employee Group. All employees must be related to an Employee Group in order to get shifts assigned, and the rate forms the basis for the calculation of pay on shifts. When an employee is added to an Employee Group the rate field is required, and if left empty it defaults to 0.
One employee can have different rates in different groups, and different individuals may have different individual rates within the same Employee Group.
The employee’s individual pay rate forms the basis for any calculations of salary, break deductions, payroll supplements and other rate adjustments. `}</p>
    <h4 {...{
      "id": "rate-adjustments-on-shift-types"
    }}>{`Rate adjustments on Shift Types`}</h4>
    <p>{`Shifts in Planday default to “Normal” shift type. Additional shift types can be defined by Admins, and every shift type can have a defined rate adjustment, either as `}</p>
    <ul>
      <li parentName="ul">{`a monetary value that replaces the employee’s rate`}</li>
      <li parentName="ul">{`a monetary value that is added to the employee’s rate`}</li>
      <li parentName="ul">{`a percentage of the employee’s rate`}</li>
    </ul>
    <h4 {...{
      "id": "payroll-supplements"
    }}>{`Payroll supplements`}</h4>
    <p>{`Normal supplements in Planday are related to shifts and get applied to each shift by triggers like a certain shift length exceeded, or when a shift’s duration falls within a defined time range. Any supplements which have been automatically associated with a shift or multiple shifts, will be included in the `}<inlineCode parentName="p">{`shiftsPayroll`}</inlineCode>{` object. These supplements can have a rate, which is calculated using the employees’ pay rate related to the Employee Group to which this particular shift belongs, and can either be `}</p>
    <ul>
      <li parentName="ul">{`a fixed monetary value per hour`}</li>
      <li parentName="ul">{`a percentage of the hourly rate`}</li>
      <li parentName="ul">{`a fixed monetary value per shift (In reality this is the same as the first point, where the duration is 1 hour only)`}</li>
    </ul>
    <p>{`In Planday, payroll supplements are generally payroll items on top of the worked hours. For example, if an employee’s pay rate is $10, and a 50% payroll supplement is triggered after 9 PM on weekends, their hourly rate after 9 PM will still be $10/hour for the shift, plus a 5$/hour supplement. There are exceptions to this rule, of course.`}</p>
    <h4 {...{
      "id": "custom-rates-on-shifts"
    }}>{`Custom rates on shifts`}</h4>
    <p>{`A little less common, sometimes a custom rate has been set on a single shift. Custom rates on shifts can be defined per hour or per shift by the Admins or the Schedule Managers, and overwrites any rates related to the employee in the given Employee Group.`}</p>
    <h4 {...{
      "id": "manual-payroll-supplements"
    }}>{`Manual payroll supplements`}</h4>
    <p>{`Manual payroll supplements can be added to single shifts and, while there can be a default rate in the manual supplements’ settings, the rates can also be defined for each instance, i.e per shift. Similarly to normal payroll supplements, these do not alter the employee’s rate, but become an additional payroll item.`}</p>
    <h3 {...{
      "id": "supplements-payroll-additional-payroll-supplements"
    }}>{`Supplements Payroll: Additional payroll supplements`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`supplementsPayroll`}</inlineCode>{` object lists any supplements that are not related to specific shifts. e.g. weekly supplements. There are certain exceptions that apply to these types of supplements. For example, they cannot have any relationship to departments.`}</p>
    <h3 {...{
      "id": "salaried-payroll-payroll-items-related-to-a-calendar-month"
    }}>{`Salaried Payroll: Payroll items related to a calendar month`}</h3>
    <h4 {...{
      "id": "employees-monthly-salaries"
    }}>{`Employees’ Monthly salaries`}</h4>
    <p>{`If any employees have a monthly salariy defined in Planday, these will be included in the salariedPayroll object.
Monthly salary is set up per employee via Planday, and an employee can only have one monthly salary.
We currently don’t support other intervals than calendar months. When setting up the monthly salary, a start date for the salary can be selected. `}</p>
    <p>{`In the event that a salaried employee started mid-month, you may wish to use the optional property `}<inlineCode parentName="p">{`returnFullSalaryForMonthlyPaid`}</inlineCode>{` (boolean) in the Payroll API in order to decide whether their a full monthly salary should be included, or only the part of the salary for the date range provided in the request.`}</p>
    <p>{`The salaried object also has a `}<inlineCode parentName="p">{`wage`}</inlineCode>{` property, which represents the unit wage for this employee, where unit is referring to the hours per month defined in relation to their monthly salary.`}</p>
    <p>{`Example:
Contracted hours: 160.5 (per month)
Salary: £3500
Wage: 21.8`}</p>
    <h4 {...{
      "id": "relevant-settings-in-planday"
    }}>{`Relevant settings in Planday`}</h4>
    <p>{`There are some settings in Planday which may be relevant to know about when building an integration between Planday and a payroll system, because they might be able to explain variances between the data in the Payroll API vs. the data from other endpoints, such as in the Schedule API.`}</p>
    <ul>
      <li parentName="ul">{`Shift type settings: shift types can have various settings which alter the payroll output, for example some of them will exclude breaks and supplements from getting applied to the shift, and some shift types are totally excluded from the payroll output. `}</li>
      <li parentName="ul">{`Departments: in some cases our customers use department numbers to identify payroll costs generated in different areas of the business. The `}<inlineCode parentName="li">{`GET /hr/v{version}/departments`}</inlineCode>{` will include department numbers if the portal has them, which can be used to map payroll data`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      